import React from "react";
import {
    EditGuesser,
    InputGuesser
} from "@api-platform/admin";
import ToolModelValueInput from "../util_components/ToolModelValueInput";

const ToolModelEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"model"} />
        <ToolModelValueInput source={"valueNm"} />
        <ToolModelValueInput source={"valueFtLbs"} />
    </EditGuesser>
);


export default ToolModelEdit;
