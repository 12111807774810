const _ResultsList = _interopRequireDefault(require("./resultsList"));
Object.defineProperty(exports, "ResultsList", {
  enumerable: true,
  get: function () {
    return _ResultsList.default;
  },
});

const _ResultsShow = _interopRequireDefault(require("./resultsShow"));
Object.defineProperty(exports, "ResultsShow", {
  enumerable: true,
  get: function () {
    return _ResultsShow.default;
  },
});

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}
