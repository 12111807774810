import React from "react";
import {
    EditGuesser,
    InputGuesser
} from "@api-platform/admin";
import ImageField from "../util_components/imageField";

const MediaObjectEdit = props => (
    <EditGuesser {...props}>
        <ImageField/>
        <InputGuesser source={"name"} />
        <InputGuesser source={"mimeType"} />
        <InputGuesser source={"uuid"} />
    </EditGuesser>
);

export default MediaObjectEdit;
