import { Chip } from '@material-ui/core'
import CloudIcon from '@material-ui/icons/Cloud'
import * as React from 'react'
import Config from '../Config'

class HealthCheckChip extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      db_status: false,
      env: 'unknow',
      version: 'unknow',
      app_name: 'CPLinQ',
    }
  }

  componentDidMount () {
    this.fetchHealthCheck()
  }

  fetchHealthCheck = () => {
    fetch(`${Config.REACT_APP_API_URL}healthcheck`).
      then((data) => data.json()).
      then((data) => {
        this.setState({
          db_status: data.db_status,
          env: data.env,
          version: data.version,
          app_name: data.app_name,
        })
        document.title = this.state.app_name
      })
  }

  render () {
    return (
      <Chip
        icon={<CloudIcon />}
        label={`api: ${this.state.version} (${this.state.env})`}
        color={this.state.db_status ? 'primary' : 'secondary'}
      />
    )
  }
}

export default HealthCheckChip
