import React, {useState} from "react";
import InputGuesser from "@api-platform/admin/lib/InputGuesser";
import ApiKeyPermissionInput from "../util_components/ApiKeyPermissionInput";
import { Create, SimpleForm } from 'react-admin';
import GeneratedApiKeyDialog from "./GeneratedApiKeyDialog";

const LicenceGenerationKeyCreate = props => {

    const [apiKey, setApiKey] = useState();

    const onSuccess = ({ data }) => {
        setApiKey(data['plainKey']);
    };

    return (
        <Create onSuccess={onSuccess} {...props}>
            <SimpleForm>
                <GeneratedApiKeyDialog apiKey={apiKey}/>
                <InputGuesser source={"name"}/>
                <ApiKeyPermissionInput source={"permissions"}/>
            </SimpleForm>
        </Create>
    );
};

export default LicenceGenerationKeyCreate;
