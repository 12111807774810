import React from "react";
import { EditGuesser, InputGuesser } from "@api-platform/admin";

const ContactEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"codeCountry"} />
        <InputGuesser source={"phoneNumber"} />
        <InputGuesser source={"email"} />
    </EditGuesser>
);

export default ContactEdit;
