import React from "react";
import { CreateGuesser, InputGuesser } from "@api-platform/admin";

const ContactCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"codeCountry"} />
        <InputGuesser source={"phoneNumber"} />
        <InputGuesser source={"email"} />
    </CreateGuesser>
);

export default ContactCreate;
