import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import React from "react";

const PartNumberShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"partNumberId"} addLabel={true} />
    <FieldGuesser source={"libelle"} addLabel={true} />
    <FieldGuesser source={"licenceLevel"} addLabel={true} />
    <FieldGuesser source={"sparePart"} addLabel={true} />
    <FieldGuesser source={"toolModel"} addLabel={true}/>
  </ShowGuesser>
);

export default PartNumberShow;
