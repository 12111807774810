import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import React from "react";

const ResultsShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"uid"} addLabel={true} />
    <FieldGuesser source={"angle"} addLabel={true} />
    <FieldGuesser source={"duration"} addLabel={true} />
    <FieldGuesser source={"batteryId"} addLabel={true} />
    <FieldGuesser source={"batteryLevel"} addLabel={true} />
    <FieldGuesser source={"batteryTemperature"} addLabel={true} />
    <FieldGuesser source={"driveTemperature"} addLabel={true} />
    <FieldGuesser source={"motorTemperature"} addLabel={true} />
    <FieldGuesser source={"reason"} addLabel={true} />
    <FieldGuesser source={"sequence"} addLabel={true} />
    <FieldGuesser source={"setup"} addLabel={true} />
    <FieldGuesser source={"date"} addLabel={true} />
    <FieldGuesser source={"tool"} addLabel={true} />
    <FieldGuesser source={"torque"} addLabel={true} />
    <FieldGuesser source={"type"} addLabel={true} />
    <FieldGuesser source={"owner"} addLabel={true} />
  </ShowGuesser>
);

export default ResultsShow;
