const _MediaObjectList = _interopRequireDefault(require("./mediaObjectList"));
Object.defineProperty(exports, "MediaObjectList", {
    enumerable: true,
    get: function () {
        return _MediaObjectList.default;
    }
});

const _MediaObjectShow = _interopRequireDefault(require("./mediaObjectShow"));
Object.defineProperty(exports, "MediaObjectShow", {
    enumerable: true,
    get: function () {
        return _MediaObjectShow.default;
    }
});

const _MediaObjectEdit = _interopRequireDefault(require("./mediaObjectEdit"));
Object.defineProperty(exports, "MediaObjectEdit", {
    enumerable: true,
    get: function () {
        return _MediaObjectEdit.default;
    }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
