import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import { Chip } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import DoneIcon from "@material-ui/icons/Done";
import React from "react";
import { DateField, ReferenceField } from "react-admin";
import { UserShowToolbar } from "./userShowToolbar";

const UserTitle = ({ record }) => {
  return (
    <span>
      User details: {record ? record.firstName : ""}{" "}
      {record ? record.lastName : ""}
    </span>
  );
};

const LegalNoticeField = ({ record }) => {
  return (
    <Chip
      label={
        <span>
          {record ? record.codeCountry : ""} {record ? record.version : ""}
        </span>
      }
      clickable
      color="primary"
      icon={record && record.active ? <DoneIcon /> : <ClearIcon />}
      variant="outlined"
    />
  );
};

const UserShow = (props) => (
  <ShowGuesser actions={<UserShowToolbar />} title={<UserTitle />} {...props}>
    <FieldGuesser source={"email"} addLabel={true} />
    <FieldGuesser source={"firstName"} addLabel={true} />
    <FieldGuesser source={"lastName"} addLabel={true} />
    <FieldGuesser source={"roles"} addLabel={true} />
    <FieldGuesser source={"company"} addLabel={true} />
    <FieldGuesser source={"job"} addLabel={true} />
    <FieldGuesser source={"isActive"} addLabel={true} />
    <FieldGuesser source={"language"} addLabel={true} />
    <FieldGuesser source={"country"} addLabel={true} />
    <FieldGuesser source={"units"} addLabel={true} />
    <FieldGuesser source={"configuration"} addLabel={true} />
    <ReferenceField
      label="Accepted privacy policy"
      source="acceptedPrivacyPolicy"
      reference="privacy_policies"
    >
      <LegalNoticeField {...props} />
    </ReferenceField>
    <ReferenceField
      label="Accepted terms of services"
      source="acceptedTermsOfService"
      reference="terms_of_services"
    >
      <LegalNoticeField {...props} />
    </ReferenceField>
    <DateField source={"createdAt"} showTime />
    <DateField source={"updatedAt"} showTime />
  </ShowGuesser>
);

export default UserShow;
