import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { AutocompleteInput, ReferenceInput } from "react-admin";

const useStyles = makeStyles(() => ({
  input: {
    width: 300,
  },
}));

const UserSearchInput = (props) => {
  const style = useStyles();
  return (
    <ReferenceInput
      source={ props.source }
      reference="users"
      className={ style.input }
      perPage={ 100 }
      page={ null }
      filterToQuery={ props.filterToQuery }
    >
      <AutocompleteInput
        resettable="true"
        optionText="email"
        helperText={ props.helperText }
        shouldRenderSuggestions={ (text) => text.length > 2 }
      />
    </ReferenceInput>
  );
};

export default UserSearchInput;
