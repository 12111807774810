import {
  fetchHydra as baseFetchHydra,
  hydraDataProvider as baseHydraDataProvider,
  useIntrospection,
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import React from "react";
import { Redirect, Route } from "react-router-dom";
import Config from "../Config";
import { isJWTTokenValid } from "./JWT";

const RedirectToLogin = () => {
  const introspect = useIntrospection();

  if (
    localStorage.getItem("token") &&
    isJWTTokenValid(localStorage.getItem("token"))
  ) {
    introspect();
    return <></>;
  }
  return <Redirect to="/login" />;
};

const apiDocumentationParser = async (entrypoint) => {
  try {
    const { api } = await parseHydraDocumentation(entrypoint, {
      headers: getHeaders,
    });
    return { api };
  } catch (result) {
    if (result.status === 401) {
      // Prevent infinite loop if the token is expired
      localStorage.removeItem("token");

      return {
        api: result.api,
        customRoutes: [<Route path="/" component={RedirectToLogin} />],
      };
    }

    throw result;
  }
};

const getHeaders = () =>
  localStorage.getItem("token")
    ? {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }
    : {};

const fetchHydra = (url, options = {}) => {
  return baseFetchHydra(url, {
    ...options,
    headers: getHeaders(),
  });
};

const dataProvider = baseHydraDataProvider(
  Config.REACT_APP_API_ENTRYPOINT,
  fetchHydra,
  apiDocumentationParser
);

export default dataProvider;
