import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import React from "react";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import CustomPagination from "../util_components/customPagination";

const exporter = async (records, _) => {
  const data = records.map((record) => {
    delete record[ "@id" ];
    delete record[ "@type" ];
    delete record[ "id" ];
    return {
      ...record,
    };
  });

  jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
    downloadCSV(csv, "partNumbers");
  });
};

const PartNumberList = (props) => (
  <ListGuesser
    exporter={ exporter }
    perPage={ 50 }
    pagination={ <CustomPagination { ...props } /> }
    { ...props }
  >
    <FieldGuesser source={ "partNumberId" } />
    <FieldGuesser source={ "libelle" } />
    <FieldGuesser source={ "licenceLevel" } />
    <FieldGuesser source={ "toolModel" } />
    <FieldGuesser source={ "sparePart" } />
  </ListGuesser>
);

export default PartNumberList;
