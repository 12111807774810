import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import ReplayIcon from '@material-ui/icons/Replay';
import TimerIcon from '@material-ui/icons/Timer';
import TrendingDownIcon from '@material-ui/icons/TrendingDown';
import Tooltip from "@material-ui/core/Tooltip";
import { createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        card: {
            minWidth: '200px',
        },
        inline: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }
    }),
);

const ToolAttributesCard = ({label,record}) => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant="h6" gutterBottom>{label ? label : ''}</Typography>
                <div className={classes.inline}>
                    <Tooltip title={`Tightening`}>
                        <RefreshIcon/>
                    </Tooltip>
                    <Typography>{record ? record.tightening : ''}</Typography>
                </div>
                <div className={classes.inline}>
                    <Tooltip title={`Untightening`}>
                        <ReplayIcon/>
                    </Tooltip>
                    <Typography>{record ? record.untightening : ''}</Typography>
                </div>
                <div className={classes.inline}>
                    <Tooltip title={`Operating time`}>
                        <TimerIcon/>
                    </Tooltip>
                    <Typography>{record ? record.operatingTime : ''}</Typography>
                </div>
                <div className={classes.inline}>
                    <Tooltip title={`Drops`}>
                        <TrendingDownIcon/>
                    </Tooltip>
                    <Typography>{record ? record.drops : ''}</Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default ToolAttributesCard;


