const _LicenceGenerationKeyShow = _interopRequireDefault(require("./licenceGenerationKeyShow"));
Object.defineProperty(exports, "LicenceGenerationKeyShow", {
    enumerable: true,
    get: function () {
        return _LicenceGenerationKeyShow.default;
    }
});

const _LicenceGenerationKeyEdit = _interopRequireDefault(require("./licenceGenerationKeyEdit"));
Object.defineProperty(exports, "LicenceGenerationKeyEdit", {
    enumerable: true,
    get: function () {
        return _LicenceGenerationKeyEdit.default;
    }
});

const _LicenceGenerationKeyList = _interopRequireDefault(require("./licenceGenerationKeyList"));
Object.defineProperty(exports, "LicenceGenerationKeyList", {
    enumerable: true,
    get: function () {
        return _LicenceGenerationKeyList.default;
    }
});

const _LicenceGenerationKeyCreate = _interopRequireDefault(require("./licenceGenerationKeyCreate"));
Object.defineProperty(exports, "LicenceGenerationKeyCreate", {
    enumerable: true,
    get: function () {
        return _LicenceGenerationKeyCreate.default;
    }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
