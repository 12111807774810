import { HydraAdmin } from "@api-platform/admin";
import ResourceGuesser from "@api-platform/admin/lib/ResourceGuesser";
import { createMuiTheme } from "@material-ui/core/styles";
import AttachFileIcon from "@material-ui/icons/AttachFile";
import BuildIcon from "@material-ui/icons/Build";
import ClassIcon from "@material-ui/icons/Class";
import EventIcon from "@material-ui/icons/Event";
import GavelIcon from "@material-ui/icons/Gavel";
import ListIcon from "@material-ui/icons/List";
import PersonIcon from "@material-ui/icons/Person";
import PhoneIcon from "@material-ui/icons/Phone";
import SettingsIcon from "@material-ui/icons/Settings";
import VerifiedUserIcon from "@material-ui/icons/VerifiedUser";
import VpnKeyIcon from "@material-ui/icons/VpnKey";
import React from "react";
import { Login, Resource } from "react-admin";
import Config from "./Config";
import {
  ContactCreate,
  ContactEdit,
  ContactList,
  ContactShow,
} from "./contact_views/contact_views";
import {
  EventCreate,
  EventEdit,
  EventList,
  EventShow,
} from "./event_views/event_views";
import authProvider from "./functions/authProvider";
import dataProvider from "./functions/dataProvider";
import {
  LicenceGenerationKeyCreate,
  LicenceGenerationKeyEdit,
  LicenceGenerationKeyList,
  LicenceGenerationKeyShow,
} from "./licences_generation_key_views/licence_generation_key_views";
import {
  LicenceCreate,
  LicenceEdit,
  LicenceList,
  LicenceShow,
} from "./licences_views/licence_views";
import { LogsList, LogsShow } from "./logs_views/logs_views";
import {
  MediaObjectEdit,
  MediaObjectList,
  MediaObjectShow,
} from "./media_object_views/media_object_views";
import {
  PartNumberCreate,
  PartNumberEdit,
  PartNumberList,
  PartNumberShow,
} from "./part_number/part_number_views";
import {
  PrivacyPolicyCreate,
  PrivacyPolicyEdit,
  PrivacyPolicyList,
  PrivacyPolicyShow,
} from "./privacy_policy_views/privacy_policy_views";
import { ResultsList, ResultsShow } from "./results_views/results_views";
import {
  StateThresholdCreate,
  StateThresholdEdit,
  StateThresholdList,
  StateThresholdShow,
} from "./state_thresholds_views/state_thresholds_views";
import {
  TermsOfServiceCreate,
  TermsOfServiceEdit,
  TermsOfServiceList,
  TermsOfServiceShow,
} from "./term_of_service_views/term_of_service_views";
import {
  ToolModelCreate,
  ToolModelEdit,
  ToolModelList,
  ToolModelShow,
} from "./tool_model_views/tool_model_views";
import {
  ToolSetupCreate,
  ToolSetupList,
  ToolSetupShow,
} from "./tool_setup_views/tool_setup_views";
import {
  ToolCreate,
  ToolEdit,
  ToolList,
  ToolShow,
} from "./tool_views/tool_views";
import {
  UserList,
  UsersCreate,
  UsersEdit,
  UserShow,
} from "./user_views/user_views";
import MyLayout from "./util_components/myLayout";
import MyMenu from "./util_components/myMenu";

const BACKGROUND_IMAGE = "/cp_bg.jpg";
const theme = createMuiTheme({
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        backgroundColor: "#000000",
      },
    },
  },
});

export default () => (
  <HydraAdmin
    menu={MyMenu}
    layout={MyLayout}
    loginPage={() => <Login backgroundImage={BACKGROUND_IMAGE} />}
    dataProvider={dataProvider}
    authProvider={authProvider}
    entrypoint={Config.REACT_APP_API_ENTRYPOINT}
    theme={theme}
  >
    <ResourceGuesser
      name="contacts"
      list={ContactList}
      show={ContactShow}
      edit={ContactEdit}
      create={ContactCreate}
      icon={<PhoneIcon />}
    />
    <ResourceGuesser
      name="tool_models"
      list={ToolModelList}
      show={ToolModelShow}
      edit={ToolModelEdit}
      create={ToolModelCreate}
      icon={<ClassIcon />}
    />
    <ResourceGuesser
      name="tools"
      list={ToolList}
      show={ToolShow}
      edit={ToolEdit}
      create={ToolCreate}
      icon={<BuildIcon />}
    />
    <Resource
      name="tool_setups"
      list={ToolSetupList}
      show={ToolSetupShow}
      create={ToolSetupCreate}
      icon={<SettingsIcon />}
    />
    <Resource name="logs" list={LogsList} show={LogsShow} icon={<ListIcon />} />
    <ResourceGuesser
      name="state_thresholds"
      list={StateThresholdList}
      show={StateThresholdShow}
      edit={StateThresholdEdit}
      create={StateThresholdCreate}
      icon={<ListIcon />}
    />
    <Resource
      name="results"
      list={ResultsList}
      show={ResultsShow}
      icon={<ListIcon />}
      options={{ label: "Tracability" }}
    />
    <ResourceGuesser
      name="privacy_policies"
      list={PrivacyPolicyList}
      show={PrivacyPolicyShow}
      edit={PrivacyPolicyEdit}
      create={PrivacyPolicyCreate}
      icon={<GavelIcon />}
    />
    <ResourceGuesser
      name="terms_of_services"
      list={TermsOfServiceList}
      show={TermsOfServiceShow}
      edit={TermsOfServiceEdit}
      create={TermsOfServiceCreate}
      icon={<GavelIcon />}
    />
    <ResourceGuesser
      name="admin/part_numbers"
      options={{ label: "Part numbers" }}
      list={PartNumberList}
      show={PartNumberShow}
      edit={PartNumberEdit}
      create={PartNumberCreate}
      icon={<VerifiedUserIcon />}
    />
    <ResourceGuesser
      name="licences"
      list={LicenceList}
      show={LicenceShow}
      edit={LicenceEdit}
      create={LicenceCreate}
      icon={<VerifiedUserIcon />}
    />
    <ResourceGuesser
      name="admin/licence_generation_keys"
      options={{ label: "API Key" }}
      list={LicenceGenerationKeyList}
      show={LicenceGenerationKeyShow}
      edit={LicenceGenerationKeyEdit}
      create={LicenceGenerationKeyCreate}
      icon={<VpnKeyIcon />}
    />
    <ResourceGuesser
      name="users"
      list={UserList}
      show={UserShow}
      edit={UsersEdit}
      create={UsersCreate}
      icon={<PersonIcon />}
    />

    <ResourceGuesser
      name="events"
      list={EventList}
      show={EventShow}
      edit={EventEdit}
      create={EventCreate}
      icon={<EventIcon />}
    />
    <ResourceGuesser
      name="media_objects"
      list={MediaObjectList}
      show={MediaObjectShow}
      edit={MediaObjectEdit}
      icon={<AttachFileIcon />}
    />
  </HydraAdmin>
);
