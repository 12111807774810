import React from 'react';
import Button from "@material-ui/core/Button";
import { useNotify, useRedirect } from 'react-admin';
import { deleteUser } from '../functions/adminProvider';
import DeleteIcon from '@material-ui/icons/Delete';

const UserDeleteButton = ({ record }) => {
    const notify = useNotify();
    const redirect = useRedirect();
    const remove = () => deleteUser(record)
        .then(() => {
            // success side effects go here
            notify('User deleted');
            redirect('/users')
        })
        .catch(error => {
            // failure side effects go here
            notify(`User deletion error: ${error.message}`, 'warning');
        });

    return <Button color="primary" startIcon={<DeleteIcon />}  onClick={remove}>Delete</Button>
};

export default UserDeleteButton;