import React from "react";
import {
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import CustomPagination from "../util_components/customPagination";

const exporter = async (records, _) => {
    const data = records.map((record) => {
        delete record[ "@id" ];
        delete record[ "@type" ];
        delete record[ "id" ];
        return {
            ...record,
            createdAt: record.createdAt ? new Date(record.createdAt).toLocaleString() : "",
            updatedAt: record.updatedAt ? new Date(record.updatedAt).toLocaleString() : "",
        };
    });

    jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
        downloadCSV(csv, "privacyPolicies");
    });
};

const PrivacyPolicyList = props => (
    <ListGuesser
        exporter={ exporter }
        perPage={ 50 }
        pagination={ <CustomPagination { ...props } /> }
        { ...props }
    >
        <FieldGuesser source={ "active" } />
        <FieldGuesser source={ "codeCountry" } />
        <FieldGuesser source={ "language" } />
        <FieldGuesser source={ "version" } />
    </ListGuesser>
);

export default PrivacyPolicyList;
