import React from 'react';

const LOGO = '/logo.png';
const Logo = () => (
    <img
        src={LOGO}
        style={{height:50}}
        alt={'Chicago pneumatic'}
    />);

export default Logo;
