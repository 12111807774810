import React from "react";
import {
    ShowGuesser,
} from "@api-platform/admin";
import {DateField, TextField} from 'react-admin';

const LicenceGenerationKeyShow = props => (
    <ShowGuesser {...props}>
        <TextField source={"name"} />
        <TextField source={"prefix"} />
        <TextField source={"permissions"} />
        <DateField source={"createdAt"} />
        <DateField source={"updatedAt"} />
    </ShowGuesser>
);

export default LicenceGenerationKeyShow;
