import { EditGuesser, InputGuesser } from "@api-platform/admin";
import React from "react";
import InputJson from "../util_components/inputJson";
import UserTextInput from "../util_components/userTextInput";

const StateThresholdEdit = (props) => (
  <EditGuesser {...props}>
    <UserTextInput />
    <InputGuesser source={"model"} />
    <InputJson source={"attributes"} />
  </EditGuesser>
);

export default StateThresholdEdit;
