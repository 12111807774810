import Button from "@material-ui/core/Button";
import LockOpenIcon from "@material-ui/icons/LockOpen";
import React from "react";
import { useNotify, useRefresh } from "react-admin";
import { activateUser } from "../functions/adminProvider";

const UserActivationButton = ({ record }) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const activate = () =>
    activateUser(record)
      .then(() => {
        // success side effects go here
        notify("User activated");
        refresh();
      })
      .catch((error) => {
        // failure side effects go here
        notify(`User activation error: ${error.message}`, "warning");
      });

  return (
    <Button color="primary" startIcon={<LockOpenIcon />} onClick={activate}>
      Activate
    </Button>
  );
};

export default UserActivationButton;
