import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import { AutocompleteInput, ReferenceInput, required } from "react-admin";
import InputJson from "../util_components/inputJson";
import UserTextInput from "../util_components/userTextInput";

const useStyles = makeStyles({
  setupCard: {
    display: "flex",
  },
  inputWidth: {
    width: 400,
  },
  typeLabel: {
    marginLeft: 5,
  },
});

const ToolSetups = (props) => {
  const classes = useStyles();
  return (
    <div>
      <ReferenceInput
        source="setup1"
        reference="tool_setups"
        allowEmpty
        className={classes.inputWidth}
        filter={{ owner: props.record["owner"] }}
        perPage={null}
        page={null}
      >
        <AutocompleteInput source="setup1" />
      </ReferenceInput>
      <ReferenceInput
        source="setup2"
        reference="tool_setups"
        allowEmpty
        className={classes.inputWidth}
        filter={{ owner: props.record["owner"] }}
        perPage={null}
        page={null}
      >
        <AutocompleteInput source="setup2" />
      </ReferenceInput>
      <ReferenceInput
        source="setup3"
        reference="tool_setups"
        allowEmpty
        className={classes.inputWidth}
        filter={{ owner: props.record["owner"] }}
        perPage={null}
        page={null}
      >
        <AutocompleteInput source="setup3" />
      </ReferenceInput>
      <ReferenceInput
        source="setup4"
        reference="tool_setups"
        allowEmpty
        className={classes.inputWidth}
        filter={{ owner: props.record["owner"] }}
        perPage={null}
        page={null}
      >
        <AutocompleteInput source="setup4" />
      </ReferenceInput>
      <ReferenceInput
        source="setup5"
        reference="tool_setups"
        allowEmpty
        className={classes.inputWidth}
        filter={{ owner: props.record["owner"] }}
        perPage={null}
        page={null}
      >
        <AutocompleteInput source="setup5" />
      </ReferenceInput>
      <ReferenceInput
        source="setup6"
        reference="tool_setups"
        allowEmpty
        className={classes.inputWidth}
        filter={{ owner: props.record["owner"] }}
        perPage={null}
        page={null}
      >
        <AutocompleteInput source="setup6" />
      </ReferenceInput>
    </div>
  );
};

const ToolEdit = (props) => (
  <EditGuesser
    {...props}
    transform={(data) => ({
      ...data,
      offlineUpdatedAt: new Date().toISOString(),
    })}
  >
    <InputGuesser source={"uuid"} />
    <UserTextInput />
    <InputGuesser source={"model"} />
    <ToolSetups {...props} />
    <InputGuesser source={"state"} />
    <InputGuesser source={"name"} validate={[required()]} />
    <InputGuesser source={"serial"} />
    <InputGuesser source={"bluetoothId"} />
    <InputJson source={"version"} />
    <InputJson source={"attributes"} />
    <InputJson source={"totalAttributes"} />
    <InputJson source={"mode"} />
    <InputJson source={"lock"} />
    <InputGuesser source={"battery"} />
    <InputGuesser source={"binaryToolOffset"} />
    <InputGuesser source={"resultToolOffset"} />
    <InputGuesser source={"archived"} />
    <InputGuesser source={"lastSync"} />
    <InputGuesser source={"mediaObject"} />
  </EditGuesser>
);

export default ToolEdit;
