import React from "react";
import Badge from "@material-ui/core/Badge";
import EventNoteTwoToneIcon from '@material-ui/icons/EventNoteTwoTone';

const EventCountField = ({ record }) => {
    return record ? (
        <Badge
            badgeContent={record.events.length}
            showZero
            color="primary">
            <EventNoteTwoToneIcon />
        </Badge>
    ) : null;
};

export default EventCountField;