import React from "react";
import RichTextInput from 'ra-input-rich-text';
import {
    EditGuesser, InputGuesser,
} from "@api-platform/admin";

const PrivacyPolicyEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"active"} />
        <InputGuesser source={"codeCountry"} />
        <InputGuesser source={"language"} />
        <InputGuesser source={"version"} />
        <RichTextInput source={"content"} />
    </EditGuesser>
);

export default PrivacyPolicyEdit;
