import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import React from "react";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import CustomPagination from "../util_components/customPagination";
import { FilterGuesserExtend } from "../util_components/FilterGuesserExtend";
import UserSearchInput from "../util_components/userSearchInput";

const exporter = async (records, fetchRelatedRecords) => {
  const models = await fetchRelatedRecords(records, "model", "tool_models");
  const owners = await fetchRelatedRecords(records, "owner", "users");
  const data = records.map((record) => {
    delete record[ "@id" ];
    delete record[ "@type" ];
    delete record[ "id" ];
    return {
      ...record,
      model:
        record.model && models[ record.model ]
          ? models[ record.model ].model
          : "",
      owner:
        record.owner && owners[ record.owner ] ? owners[ record.owner ].email : "",
      offlineUpdatedAt: record.offlineUpdatedAt ? new Date(record.offlineUpdatedAt).toLocaleString() : "",
    };
  });

  jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
    downloadCSV(csv, "statesThresholds");
  });
};

const StateThresholdList = (props) => (
  <ListGuesser
    exporter={ exporter }
    perPage={ 50 }
    pagination={ <CustomPagination { ...props } /> }
    filters={
      <FilterGuesserExtend
        filterstoadd={ [
          {
            name: "owner",
            component: (
              <UserSearchInput
                source={ "owner" }
                key="owner"
                helperText=""
                filterToQuery={ searchText => ({ email: searchText }) }
              ></UserSearchInput>
            ),
          },
        ] }
        { ...props }
      />
    }
    { ...props }
  >
    <FieldGuesser source={ "model" } sortBy="model.model" />
    <FieldGuesser source={ "attributes" } sortable={ false } />
    <FieldGuesser source={ "uuid" } />
    <FieldGuesser source={ "owner" } sortBy="owner.email" />
  </ListGuesser>
);

export default StateThresholdList;
