import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import React from "react";

const ToolSetupCreate = (props) => (
  <CreateGuesser {...props}>
    <InputGuesser source={"partNumberId"} />
    <InputGuesser source={"libelle"} />
    <InputGuesser source={"licenceLevel"} />
    <InputGuesser source={"sparePart"} />
    <InputGuesser source={"toolModel"} />
  </CreateGuesser>
);

export default ToolSetupCreate;
