import { Tooltip, Typography } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import WarningIcon from "@material-ui/icons/Warning";
import PropTypes from "prop-types";
import React from "react";

const ToolModelValueField = ({ record, source, units }) => {
  if (!record || record[source] == null) return null;
  const { min, max, alert } = record && record[source];
  const iconStyle = { fontSize: 25, verticalAlign: "middle" };

  return (
    <div>
      <Tooltip title="minimal value">
        <Typography variant="body2">
          {" "}
          <ExpandMoreIcon style={iconStyle} /> {min} {units}
        </Typography>
      </Tooltip>
      <Tooltip title="alert value">
        <Typography variant="body2">
          {" "}
          <WarningIcon style={iconStyle} /> {alert} {units}{" "}
        </Typography>
      </Tooltip>
      <Tooltip title="maximal value">
        <Typography variant="body2">
          {" "}
          <ExpandLessIcon style={iconStyle} /> {max} {units}{" "}
        </Typography>
      </Tooltip>
    </div>
  );
};

ToolModelValueField.propTypes = {
  source: PropTypes.string.isRequired,
  units: PropTypes.string.isRequired,
};
ToolModelValueField.defaultProps = {
  withLabel: false,
};
export default ToolModelValueField;
