import React from 'react';
import ToolSetupIcon from "../util_components/toolSetupTypeIcon";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles({
    toolSetupType: {
        display: "flex",
        alignItems: "center",
    },
    typeLabel: {
        marginLeft: 5
    }
});

const ToolSetupTypeField = ({record, source}) => {
    const classes = useStyles();

    if (!record) return null;

    const label = record[source].replace('_', ' ');
    const labelType = label.charAt(0).toUpperCase() + label.toLowerCase().slice(1);
    const type = record[source];
    return <span className={classes.toolSetupType}>
        <ToolSetupIcon type={type} height={25}/>
        <span className={classes.typeLabel}> {labelType} </span>
    </span>;
};

export default ToolSetupTypeField;
