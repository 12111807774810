import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import React from "react";
import InputJson from "../util_components/inputJson";
import UserTextInput from "../util_components/userTextInput";

const StateThresholdCreate = (props) => (
  <CreateGuesser {...props}>
    <UserTextInput />
    <InputGuesser source={"model"} />
    <InputJson source={"attributes"} />
  </CreateGuesser>
);

export default StateThresholdCreate;
