import PropTypes from "prop-types";
import React from "react";
import { RadioButtonGroupInput } from "react-admin";

const TorqueUnitInput = ({ source }) => (
  <RadioButtonGroupInput
    source={source}
    rows="true"
    choices={[
      { id: "Nm", name: "Newton meter" },
      { id: "Ft.lbs", name: "Foot-pound" },
    ]}
  />
);

TorqueUnitInput.propTypes = {
  source: PropTypes.string.isRequired,
};
export default TorqueUnitInput;
