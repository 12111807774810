import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import React from "react";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import AvatarField from "../util_components/avatarField";
import CustomPagination from "../util_components/customPagination";

const exporter = async (records, _) => {
  const data = records.map((record) => {
    delete record[ "@id" ];
    delete record[ "@type" ];
    delete record[ "id" ];
    return {
      ...record,
      createdAt: record.createdAt ? new Date(record.createdAt).toLocaleString() : "",
      updatedAt: record.updatedAt ? new Date(record.updatedAt).toLocaleString() : "",
    };
  });

  jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
    downloadCSV(csv, "mediaObjects");
  });
};

const MediaObjectList = (props) => (
  <ListGuesser
    exporter={ exporter }
    perPage={ 50 }
    pagination={ <CustomPagination { ...props } /> }
    { ...props }
  >
    <AvatarField source={ "file" } />
    <FieldGuesser source={ "name" } />
    <FieldGuesser source={ "mimeType" } />
    <FieldGuesser source={ "uuid" } />
    <FieldGuesser source={ "createdAt" } />
    <FieldGuesser source={ "updatedAt" } />
  </ListGuesser>
);

export default MediaObjectList;
