import Config from "../Config";

const API_URI = Config.REACT_APP_API_ENTRYPOINT;

export function activateUser (user) {
    const { email } = user;
    const request = new Request(API_URI+'/admin/user/activate', {
        method: 'POST',
        body: JSON.stringify({ email }),
        headers: new Headers({
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
        )
    });

    return fetch(request)
        .then(response => {
            if (response.status < 200 || response.status >= 300) throw new Error(response.statusText);
            return response.json();
        });
}

export function deleteUser (user) {
    const request = new Request(`${API_URI}/admin/users/${user.uuid}`, {
        method: 'DELETE',
        headers: new Headers({
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
        )
    });

    return fetch(request)
        .then(response => {
            if (response.status !== 204) {
                throw new Error(response.statusText);
            }
            return true;
        });
}
