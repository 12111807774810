const _TermsOfServiceCreate = _interopRequireDefault(require("./termsOfServiceCreate"));
Object.defineProperty(exports, "TermsOfServiceCreate", {
    enumerable: true,
    get: function () {
        return _TermsOfServiceCreate.default;
    }
});

const _TermsOfServiceList = _interopRequireDefault(require("./termsOfServiceList"));
Object.defineProperty(exports, "TermsOfServiceList", {
    enumerable: true,
    get: function () {
        return _TermsOfServiceList.default;
    }
});

const _TermsOfServiceShow = _interopRequireDefault(require("./termsOfServiceShow"));
Object.defineProperty(exports, "TermsOfServiceShow", {
    enumerable: true,
    get: function () {
        return _TermsOfServiceShow.default;
    }
});

const _TermsOfServiceEdit = _interopRequireDefault(require("./termsOfServiceEdit"));
Object.defineProperty(exports, "TermsOfServiceEdit", {
    enumerable: true,
    get: function () {
        return _TermsOfServiceEdit.default;
    }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
