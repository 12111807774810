import React from 'react';
import Chip from '@material-ui/core/Chip';
import { makeStyles } from '@material-ui/core/styles';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import WarningIcon from '@material-ui/icons/Warning';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';

const useStyles = makeStyles({
    bad: {
        color: 'white',
        textTransform: 'capitalize',
        backgroundColor: '#e53935',
        minWidth: 90,
    },
    average: {
        color: 'white',
        textTransform: 'capitalize',
        backgroundColor: '#fb8c00',
        minWidth: 90,
    },
    good: {
        color: 'white',
        textTransform: 'capitalize',
        backgroundColor: '#43a047',
        minWidth: 90,
    },
});

const StateChip = ({ record }) => {
    const classes = useStyles();
    let state = record ? record.state : '';
    switch (state) {
        case 'bad':
            return <Chip label={state} icon={
                <ThumbDownIcon style={{ color: 'white', fontSize: 16 }}/>
            } className={classes.bad}/>;
        case 'average':
            return <Chip label={state} icon={
                <WarningIcon style={{ color: 'white', fontSize: 16 }}/>
            } className={classes.average}/>;
        case 'good':
            return <Chip label={state} icon={
                <ThumbUpIcon style={{ color: 'white', fontSize: 16 }}/>
            } className={classes.good}/>;
        default:
            return <Chip label={state}/>;
    }
};

export default StateChip;
