import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import React from "react";
import { required } from "react-admin";
import InputJson from "../util_components/inputJson";
import UserTextInput from "../util_components/userTextInput";

const ToolCreate = (props) => (
  <CreateGuesser
    {...props}
    transform={(data) => ({
      ...data,
      offlineUpdatedAt: new Date().toISOString(),
    })}
  >
    <UserTextInput />
    <InputGuesser source={"model"} />
    <InputGuesser source={"name"} validate={[required()]} />
    <InputGuesser source={"serial"} />
    <InputGuesser source={"bluetoothId"} />
    <InputJson source={"version"} />
    <InputJson source={"attributes"} />
    <InputJson source={"totalAttributes"} />
    <InputJson source={"mode"} />
    <InputJson source={"lock"} />
    <InputGuesser source={"battery"} />
    <InputGuesser source={"binaryToolOffset"} />
    <InputGuesser source={"resultToolOffset"} />
    <InputGuesser source={"archived"} />
    <InputGuesser source={"lastSync"} />
    <InputGuesser source={"mediaObject"} />
  </CreateGuesser>
);

export default ToolCreate;
