import { EditGuesser, InputGuesser } from "@api-platform/admin";
import React from "react";
import {
  AutocompleteInput,
  maxLength,
  minLength,
  ReferenceInput,
  required,
  regex,
} from 'react-admin'

const validateSerialNumber = [required(), minLength(14), maxLength(14)];
const validateToolUuid = [
  required(),
  minLength(24),
  maxLength(24),
  regex(/^[a-z0-9]*$/, "Only lowercase letters and numbers allowed"),
];

const LicenceEdit = (props) => (
  <EditGuesser {...props}>
    <InputGuesser source={"serialNumber"} validate={validateSerialNumber} />
    <InputGuesser source={"toolUuid"} validate={validateToolUuid} />
    <ReferenceInput
      source={"partNumber"}
      reference="admin/part_numbers"
      perPage={null}
      page={null}
    >
      <AutocompleteInput optionText="partNumberId" source="partNumber" />
    </ReferenceInput>
  </EditGuesser>
);

export default LicenceEdit;
