import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import React from "react";
import CustomPagination from "../util_components/customPagination";
import ToolModelValueField from "../util_components/ToolModelValueField";

const exporter = async (records, _) => {
  const data = records.map((record) => {
    delete record[ "@id" ];
    delete record[ "@type" ];
    delete record[ "id" ];
    return {
      ...record,
    };
  });

  jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
    downloadCSV(csv, "toolModels");
  });
};

const ToolModelList = (props) => (
  <ListGuesser
    exporter={ exporter }
    perPage={ 50 }
    pagination={ <CustomPagination { ...props } /> }
    { ...props }
  >
    <FieldGuesser source={ "model" } />
    <ToolModelValueField source={ "valueNm" } units={ "Nm" } sortable={ false } />
    <ToolModelValueField
      source={ "valueFtLbs" }
      units={ "Ft lbs" }
      sortable={ false }
    />
  </ListGuesser>
);

export default ToolModelList;
