import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import React from "react";
import { ReferenceField } from "react-admin";
import CustomPagination from "../util_components/customPagination";
import { FilterGuesserExtend } from "../util_components/FilterGuesserExtend";
import ListActionsExtend from "../util_components/ListActionsExtend";
import SetupSearchInput from "../util_components/setupSearchInput";
import ToolSearchInput from "../util_components/toolSearchInput";
import UserSearchInput from "../util_components/userSearchInput";
import ExportResultsButton from './exportResultsButton'

const ResultsList = (props) => (
  <ListGuesser
    actions={
      <ListActionsExtend actionsToAdd={[
        {
          name: 'exportResultsButton',
          component: (
            <ExportResultsButton />
          ),
        },
      ]} />}
    exporter={false}
    perPage={50}
    pagination={<CustomPagination {...props} />}
    filters={
      <FilterGuesserExtend
        filterstoadd={ [
          {
            name: "owner",
            component: (
              <UserSearchInput
                source={ "owner" }
                key="owner"
                helperText=""
                filterToQuery={ searchText => ({ email: searchText }) }
              ></UserSearchInput>
            ),
          },
          {
            name: "setup",
            component: (
              <SetupSearchInput
                source={ "setup" }
                key="setup"
                helperText=""
                filterToQuery={ searchText => ({ name: searchText }) }
              ></SetupSearchInput>
            ),
          },
          {
            name: "tool",
            component: (
              <ToolSearchInput
                source={ "tool" }
                key="tool"
                helperText=""
                filterToQuery={ searchText => ({ serial: searchText }) }
              ></ToolSearchInput>
            ),
          },
        ] }
        { ...props }
      />
    }
    { ...props }
  >
    <FieldGuesser source={ "angle" } />
    <FieldGuesser source={ "duration" } />
    <FieldGuesser source={ "reason" } />
    <FieldGuesser source={ "sequence" } />
    <FieldGuesser source={ "setup" } sortBy="setup.name" />
    <FieldGuesser source={ "date" } />
    <ReferenceField
      source="tool"
      reference="tools"
      sortBy="tool.serial"
    >
      <FieldGuesser source="serial" />
    </ReferenceField>
    {/* <FieldGuesser source={"tool"} sortBy="tool.name" /> */ }
    <FieldGuesser source={ "torque" } sortable={ false } />
    <FieldGuesser source={ "type" } />
    <FieldGuesser source={ "owner" } sortBy="owner.email" />
  </ListGuesser>
);

export default ResultsList;
