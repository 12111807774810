import { EditGuesser, InputGuesser } from "@api-platform/admin";
import React from "react";
import { required } from "react-admin";
import InputJson from "../util_components/inputJson";
import UserTextInput from "../util_components/userTextInput";

const EventEdit = (props) => (
  <EditGuesser
    {...props}
    transform={(data) => ({
      ...data,
      offlineUpdatedAt: new Date().toISOString(),
    })}
  >
    <UserTextInput />
    <InputGuesser source={"tool"} />
    <InputGuesser source={"type"} />
    <InputGuesser source={"name"} />
    <InputGuesser source={"description"} />
    <InputGuesser source={"date"} validate={[required()]} />
    <InputJson source={"attributes"} />
    <InputJson source={"cost"} />
    <InputGuesser source={"problemType"} />
    <InputGuesser source={"uuid"} />
    <InputGuesser source={"state"} />
    <InputGuesser source={"mediaObject"} />
  </EditGuesser>
);

export default EventEdit;
