import * as React from 'react';
import { useSelector } from 'react-redux';
import {useMediaQuery, Divider, Chip} from '@material-ui/core';
import { MenuItemLink, getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import { version as AppVersionNumber } from '../../package.json';
import HealthCheckChip from "./healthCheckChip";
import WebAssetIcon from '@material-ui/icons/WebAsset';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';

const MyMenu = ({ onMenuClick, logout }) => {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.down('xs'));
    const open = useSelector(state => state.admin.ui.sidebarOpen);
    const resources = useSelector(getResources);

    function getReadableResourceName(name) {
        return (name[0].toUpperCase() + name.slice(1)).replaceAll('_', ' ');
    }

    return (
        <div>
            {resources.map(resource => {
                const menuItemLink = <MenuItemLink
                    key={resource.name}
                    to={`/${resource.name}`}
                    primaryText={(resource.options && resource.options.label) || getReadableResourceName(resource.name)}
                    leftIcon={resource.icon && resource.icon}
                    onClick={onMenuClick}
                    sidebarIsOpen={open}
                />
                if(resource.name === 'users' ) {
                    // Add custom admin menu next to user menu
                    const menuAdminItemLink = <MenuItemLink
                        key="admin"
                        to={{
                            pathname: "/users",
                            search: "?isAdmin=true"
                        }}
                        primaryText="Admins"
                        leftIcon={<SupervisorAccountIcon/>}
                        onClick={onMenuClick}
                        sidebarIsOpen={open}
                    />
                    return [menuAdminItemLink, menuItemLink]
            
                }
                return menuItemLink
            })}
            {isXSmall && logout}
            <Divider/>
            { open && <div
                style={{marginTop: 20, height: 100, display: "flex",
                    flexDirection: "column", alignItems: "center", justifyContent: "space-evenly"}}>
                <Chip
                    icon={<WebAssetIcon />}
                    label={`bo: v${AppVersionNumber}`}
                />
                <HealthCheckChip/>
            </div>
            }
        </div>
    );
}

export default withRouter(MyMenu);
