import React from "react";
import {
    EditGuesser, InputGuesser,
} from "@api-platform/admin";
import ApiKeyPermissionInput from "../util_components/ApiKeyPermissionInput";

const LicenceGenerationKeyEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"name"} />
        <ApiKeyPermissionInput source={"permissions"} />
    </EditGuesser>
);

export default LicenceGenerationKeyEdit;
