import React from "react";
import Badge from "@material-ui/core/Badge";
import SettingsTwoToneIcon from '@material-ui/icons/SettingsTwoTone';

const countEvent = (record) =>
{
    let counter = 0;
    if(record['setup1'] !== null) counter ++;
    if(record['setup2'] !== null) counter ++;
    if(record['setup3'] !== null) counter ++;
    if(record['setup4'] !== null) counter ++;
    if(record['setup5'] !== null) counter ++;
    if(record['setup6'] !== null) counter ++;
    return counter;
}

const SetupCountField = ({ record }) => {
    return record ? (
        <Badge
            badgeContent={countEvent(record)}
            showZero
            color="primary">
            <SettingsTwoToneIcon />
        </Badge>
    ) : null;
};

export default SetupCountField;
