import React from "react";
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";

const ContactShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"codeCountry"} addLabel={true} />
        <FieldGuesser source={"phoneNumber"} addLabel={true} />
        <FieldGuesser source={"email"} addLabel={true} />
    </ShowGuesser>
);

export default ContactShow;
