import React from "react";
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";

const MediaObjectShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"file"} addLabel={true} />
        <FieldGuesser source={"name"} addLabel={true} />
        <FieldGuesser source={"mimeType"} addLabel={true} />
        <FieldGuesser source={"uuid"} addLabel={true} />
        <FieldGuesser source={"id"} addLabel={true} />
        <FieldGuesser source={"createdAt"} addLabel={true} />
        <FieldGuesser source={"updatedAt"} addLabel={true} />
    </ShowGuesser>
);

export default MediaObjectShow;
