import React from "react";
import { Pagination } from "react-admin";

const CustomPagination = (props) => {
  const filteredProps = {};
  Object.keys(props)
    .filter(
      (key) =>
        key !== "hasList" &&
        key !== "hasEdit" &&
        key !== "hasCreate" &&
        key !== "hasShow"
    )
    .forEach((key) => (filteredProps[key] = props[key]));

  return <Pagination rowsPerPageOptions={[25, 50, 100]} {...filteredProps} />;
};

export default CustomPagination;
