import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import React from "react";

const StateThresholdShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"model"} addLabel={true} />
    <FieldGuesser source={"attributes"} addLabel={true} />
    <FieldGuesser source={"uuid"} addLabel={true} />
    <FieldGuesser source={"owner"} addLabel={true} />
  </ShowGuesser>
);

export default StateThresholdShow;
