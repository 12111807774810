const _PartNumberCreate = _interopRequireDefault(require("./partNumberCreate"));
Object.defineProperty(exports, "PartNumberCreate", {
  enumerable: true,
  get: function () {
    return _PartNumberCreate.default;
  },
});

const _PartNumberEdit = _interopRequireDefault(require("./partNumberEdit"));
Object.defineProperty(exports, "PartNumberEdit", {
  enumerable: true,
  get: function () {
    return _PartNumberEdit.default;
  },
});

const _PartNumberList = _interopRequireDefault(require("./partNumberList"));
Object.defineProperty(exports, "PartNumberList", {
  enumerable: true,
  get: function () {
    return _PartNumberList.default;
  },
});

const _PartNumberShow = _interopRequireDefault(require("./partNumberShow"));
Object.defineProperty(exports, "PartNumberShow", {
  enumerable: true,
  get: function () {
    return _PartNumberShow.default;
  },
});

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}
