const _LicenceShow = _interopRequireDefault(require("./licenceShow"));
Object.defineProperty(exports, "LicenceShow", {
    enumerable: true,
    get: function () {
        return _LicenceShow.default;
    }
});

const _LicenceEdit = _interopRequireDefault(require("./licenceEdit"));
Object.defineProperty(exports, "LicenceEdit", {
    enumerable: true,
    get: function () {
        return _LicenceEdit.default;
    }
});

const _LicenceList = _interopRequireDefault(require("./licenceList"));
Object.defineProperty(exports, "LicenceList", {
    enumerable: true,
    get: function () {
        return _LicenceList.default;
    }
});

const _LicenceCreate = _interopRequireDefault(require("./licenceCreate"));
Object.defineProperty(exports, "LicenceCreate", {
    enumerable: true,
    get: function () {
        return _LicenceCreate.default;
    }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
