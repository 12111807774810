import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

import Battery20Icon from '@material-ui/icons/Battery20';
import Battery30Icon from '@material-ui/icons/Battery30';
import Battery50Icon from '@material-ui/icons/Battery50';
import Battery60Icon from '@material-ui/icons/Battery60';
import Battery80Icon from '@material-ui/icons/Battery80';
import Battery90Icon from '@material-ui/icons/Battery90';
import BatteryFullIcon from '@material-ui/icons/BatteryFull';
import BatteryAlertIcon from '@material-ui/icons/BatteryAlert';
import BatteryUnknownIcon from '@material-ui/icons/BatteryUnknown';

const BatteryField = ({ record }) => {
    let battery = record && record.battery;
    return  <Tooltip title={`${battery}%`}>
            {
                (battery === 100) ? <BatteryFullIcon/> :
                (battery >= 90) ? <Battery90Icon/> :
                (battery >= 80) ? <Battery80Icon/> :
                (battery >= 60) ? <Battery60Icon/> :
                (battery >= 50) ? <Battery50Icon/> :
                (battery >= 30) ? <Battery30Icon/> :
                (battery >= 20) ? <Battery20Icon/> :
                (battery >= 0 || !battery) ? <BatteryAlertIcon/> :
                <BatteryUnknownIcon/>
            }
        </Tooltip>
};

export default BatteryField;
