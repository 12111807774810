import React from "react";
import { TextInput } from 'react-admin';


const InputJson = (props) => {

    let isValidJson = true;

    const parseInput = (input) => {
        try {
            const json = JSON.parse(input)
            isValidJson = true;
            return json
        } catch (exception) {
            isValidJson = false;
        }
    }

    const formatJson = (json) => {
        try {
            const jsonString = JSON.stringify(json);
            isValidJson = true;
            return jsonString;
        } catch (exception) {
            isValidJson = false;
        }
    }

    const validateJson = () => {
        return isValidJson ? undefined : 'Invalid JSON';
    }

    return <TextInput {...props}
        format={ formatJson }
        parse={ parseInput }
        validate={ validateJson }
        multiline
    />
};

export default InputJson;
