import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as PowerLogo } from '../assets/setup_type/icon-power.svg';
import { ReactComponent as AngleLogo } from '../assets/setup_type/icon-angle.svg';
import { ReactComponent as TorqueLogo } from '../assets/setup_type/icon-torque.svg';
import { ReactComponent as TorqueCheckLogo } from '../assets/setup_type/icon-torquecheck.svg';


const ToolSetupIcon = ({type, color = 'black'}) => {
    switch (type) {
        case 'ANGLE':
            return <AngleLogo fill={color}/>
        case 'POWER':
            return <PowerLogo fill={color}/>
        case 'TORQUE':
            return <TorqueLogo fill={color}/>
        case 'TORQUE_CHECK':
            return <TorqueCheckLogo fill={color}/>
        default:
            console.error(`type ${type} is not implemented`);
    }
};

ToolSetupIcon.propTypes = {
    type: PropTypes.string,
    height: PropTypes.number,
    color: PropTypes.string
}
export default ToolSetupIcon;
