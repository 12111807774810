import React from "react";
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";
import { RichTextField } from 'react-admin';

const PrivacyPolicyShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source={"codeCountry"} addLabel={true} />
        <FieldGuesser source={"language"} addLabel={true} />
        <FieldGuesser source={"version"} addLabel={true} />
        <RichTextField source={"content"}/>
    </ShowGuesser>
);

export default PrivacyPolicyShow;
