import React from 'react';
import CardMedia from '@material-ui/core/CardMedia';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    media: {
        width:300,
        height:300
    },
}));

const ImageField = ({record}) => {
    const classes = useStyles();
    return (
        <CardMedia
            className={classes.media}
            image={record.file}
            title="Contemplative Reptile"
        />
    );
};

export default ImageField;
