import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import React, { useEffect, useState } from "react";
import {
  Error,
  Loading,
  NumberInput,
  SelectInput,
  useDataProvider,
} from "react-admin";
import InputJson from "../util_components/inputJson";
import UserTextInput from "../util_components/userTextInput";

const ToolSetupCreate = (props) => {
  const dataProvider = useDataProvider();
  const [hardwareId, setHardwareId] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState();

  const generateHardwareId = (setups) => {
    let generatedHardwareId;
    do {
      const newId = Math.floor(Math.random() * 2147483645 + 3);
      if (!setups.find((setup) => setup.hardwareId === newId)) {
        generatedHardwareId = newId;
        break;
      }
    } while (true);
    return generatedHardwareId;
  };

  useEffect(() => {
    dataProvider
      .getList("tool_setups", { pagination: {}, sort: {} })
      .then(({ data }) => {
        const generatedHardwareId = generateHardwareId(data);
        setHardwareId(generatedHardwareId);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [dataProvider]);

  if (loading) return <Loading />;
  if (error) return <Error />;

  return (
    <CreateGuesser {...props}>
      <UserTextInput />
      <InputGuesser source={"name"} />
      <SelectInput
        source="type"
        choices={[
          { id: "ANGLE", name: "Angle" },
          { id: "TORQUE", name: "Torque" },
          { id: "POWER", name: "Power" },
        ]}
      />
      <InputJson source={"setup"} />
      <NumberInput source="hardwareId" defaultValue={hardwareId} disabled />
    </CreateGuesser>
  );
};

export default ToolSetupCreate;
