import React from "react";
import RichTextInput from 'ra-input-rich-text';
import CreateGuesser from "@api-platform/admin/lib/CreateGuesser";
import InputGuesser from "@api-platform/admin/lib/InputGuesser";

const TermsOfServiceCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"active"} />
        <InputGuesser source={"codeCountry"} />
        <InputGuesser source={"language"} />
        <InputGuesser source={"version"} />
        <RichTextInput source={"content"} />
    </CreateGuesser>
);

export default TermsOfServiceCreate;
