import { FieldGuesser, ShowGuesser } from "@api-platform/admin";
import React from "react";
import ToolModelValueField from "../util_components/ToolModelValueField";

const ToolModelShow = (props) => (
  <ShowGuesser {...props}>
    <FieldGuesser source={"model"} addLabel={true} />
    <ToolModelValueField source={"valueNm"} units={"Nm"} addLabel={true} />
    <ToolModelValueField
      source={"valueFtLbs"}
      units={"Ft lbs"}
      addLabel={true}
    />
  </ShowGuesser>
);

export default ToolModelShow;
