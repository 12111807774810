import React from "react";
import { TextInput, useQueryWithStore } from "react-admin";

const UserTextInput = () => {
  const { data } = useQueryWithStore({
    type: "getList",
    resource: "users",
    payload: {
      pagination: { page: 0, perPage: 0 },
      sort: { field: "name", order: "DESC" },
      filter: {},
    },
  });

  return (
    <TextInput
      source="owner"
      format={(v) => {
        const owner = data && data.find((dataItem) => dataItem["@id"] === v);
        return owner ? owner.email : v;
      }}
      parse={(v) => {
        const owner = data.find((dataItem) => dataItem.email === v);
        return owner ? owner["@id"] : v;
      }}
    />
  );
};

export default UserTextInput;
