import { sanitizeListRestProps, useListContext } from "ra-core";
import { CreateButton, ExportButton, TopToolbar } from "ra-ui-materialui";
import React, { cloneElement } from "react";

const ListActions = (props) => {
  const {
    className,
    exporter,
    filters,
    maxResults,
    actionsToAdd,
    ...rest
  } = props;
  const {
    currentSort,
    resource,
    displayedFilters,
    filterValues,
    hasCreate,
    basePath,
    showFilter,
    total,
  } = useListContext();
  return (
    <TopToolbar className={className} {...sanitizeListRestProps(rest)}>
      {filters &&
        cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: "button",
        })}
      {hasCreate &&
        !actionsToAdd.find(
          (actionToAdd) => actionToAdd.name === "hasCreate"
        ) && <CreateButton basePath={basePath} />}
      {!!exporter && (
        <ExportButton
          disabled={total === 0}
          resource={resource}
          sort={currentSort}
          filterValues={filterValues}
          maxResults={999999}
        />
      )}
      {actionsToAdd && actionsToAdd.map((actionToAdd) => actionToAdd.component)}
    </TopToolbar>
  );
};

export default ListActions;
