import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from "prop-types";

GeneratedApiKeyDialog.propTypes = {
    apiKey: PropTypes.string.isRequired,
}

export default function GeneratedApiKeyDialog(props) {
    const [close, closeDialog] = React.useState(false);

    const handleClose = () => {
        closeDialog(true);
    };

    return (
        <div>
            <Dialog
                open={!close && props.apiKey}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"New API Key Generated"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        New API Key created and <b>it will be displayed only now</b>,
                        <div style={{
                            backgroundColor: '#a9a9a9', textAlign: 'center', padding: '5px',
                            margin:'10px', fontSize: '14px'}}>
                            <b>{props.apiKey}</b>
                        </div>
                        Please store it somewhere safe because as soon as you navigate away from this page, we will not be able to retrieve or restore this generated token.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary" autoFocus>
                        Agree
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
