import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import { CreateButton } from "ra-ui-materialui";
import React from "react";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import CustomPagination from "../util_components/customPagination";
import ListActionsExtend from "../util_components/ListActionsExtend";

const exporter = async (records, fetchRelatedRecords) => {
  const privacyPolicies = await fetchRelatedRecords(records, "acceptedPrivacyPolicy", "privacy_policies");
  const termsOfServices = await fetchRelatedRecords(records, "acceptedTermsOfService", "terms_of_services");
  const data = records.map((record) => {
    delete record[ "@id" ];
    delete record[ "@type" ];
    delete record[ "id" ];
    let acceptedPrivacyPolicytmp = "";
    if (record.acceptedPrivacyPolicy && privacyPolicies[ record.acceptedPrivacyPolicy ]) {
      const { codeCountry, version, active } = privacyPolicies[ record.acceptedPrivacyPolicy ];
      acceptedPrivacyPolicytmp = {
        codeCountry,
        version,
        active
      }
    }
    let acceptedTermsOfServiceTmp = "";
    if (record.acceptedTermsOfService && termsOfServices[ record.acceptedTermsOfService ]) {
      const { codeCountry, version, active } = termsOfServices[ record.acceptedTermsOfService ];
      acceptedTermsOfServiceTmp = {
        codeCountry,
        version,
        active
      }
    }
    return {
      ...record,
      acceptedPrivacyPolicy: acceptedPrivacyPolicytmp,
      acceptedTermsOfService: acceptedTermsOfServiceTmp,
      createdAt: record.createdAt ? new Date(record.createdAt).toLocaleString() : "",
      updatedAt: record.updatedAt ? new Date(record.updatedAt).toLocaleString() : "",
    };
  });

  jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
    downloadCSV(csv, "users");
  });
};

const UserList = (props) => {
  // Look for filter on admin resource
  let params = new URLSearchParams(props.location.search);
  const filter =
    params.get("isAdmin") === "true"
      ? { roles: "ROLE_ADMIN" }
      : { roles: "!ROLE_ADMIN" };

  return (
    <ListGuesser
      bulkActionButtons={ false }
      exporter={ exporter }
      perPage={ 50 }
      pagination={ <CustomPagination { ...props } /> }
      filter={ filter }
      actions={
        <ListActionsExtend
          actionsToAdd={ [
            {
              name: "hasCreate",
              component: (
                <CreateButton
                  to={ `/users/create?isAdmin=${ !!params.get("isAdmin") }` }
                />
              ),
            },
          ] }
        />
      }
      { ...props }
    >
      <FieldGuesser source="isActive" />
      <FieldGuesser source="email" />
      <FieldGuesser source="firstName" />
      <FieldGuesser source="lastName" />
      <FieldGuesser source="job" />
      <FieldGuesser source="company" />
      <FieldGuesser source="country" />
      <FieldGuesser source="createdAt" />
      <FieldGuesser source="updatedAt" />
    </ListGuesser>
  );
};

export default UserList;
