import React from "react";
import {
    CreateGuesser,
    InputGuesser
} from "@api-platform/admin";
import ToolModelValueInput from "../util_components/ToolModelValueInput";

const ToolModelCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"model"} />
        <ToolModelValueInput source={"valueNm"} />
        <ToolModelValueInput source={"valueFtLbs"} />
    </CreateGuesser>
);

export default ToolModelCreate;
