import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import React from "react";
import jsonExport from "jsonexport/dist";
import {
  ReferenceField,
  downloadCSV,
  TextInput,
} from 'react-admin'
import CustomPagination from "../util_components/customPagination";

const exporter = async (records, fetchRelatedRecords) => {
  const partNumber = await fetchRelatedRecords(records, "partNumber", "admin/part_numbers");
  const data = records.map((record) => {
    delete record[ "@id" ];
    delete record[ "@type" ];
    delete record[ "id" ];
    return {
      ...record,
      licenceLevel: record.partNumber && partNumber[ record.partNumber ]
        ? partNumber[ record.partNumber ].licenceLevel
        : "",
      partNumber: record.partNumber && partNumber[ record.partNumber ]
        ? partNumber[ record.partNumber ].partNumberId
        : "",
      partNumberLabel: record.partNumber && partNumber[ record.partNumber ]
        ? partNumber[ record.partNumber ].libelle
        : "",
      createdAt: record.createdAt ? new Date(record.createdAt).toLocaleString() : "",
      updatedAt: record.updatedAt ? new Date(record.updatedAt).toLocaleString() : "",
    };
  });

  jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
    downloadCSV(csv, "licences");
  });
};

const licenceFilters = [
  <TextInput label="Tool uuid" source="toolUuid" />,
  <TextInput label="Serial number" source="serialNumber" />,
  <TextInput label="Part number" source="partNumber" />
];

const LicenceList = (props) => (
  <ListGuesser
    exporter={ exporter }
    perPage={ 50 }
    pagination={ <CustomPagination { ...props } /> }
    filters={ licenceFilters }
    { ...props }
  >
    <FieldGuesser source={ "serialNumber" } />
    <FieldGuesser source={ "toolUuid" } />
    <ReferenceField
      label="Licence level"
      source="partNumber"
      reference="admin/part_numbers"
      sortBy="partNumber.licenceLevel"
    >
      <FieldGuesser source="licenceLevel" />
    </ReferenceField>
    <ReferenceField
      source="partNumber"
      reference="admin/part_numbers"
      sortBy="partNumber.partNumberId"
    >
      <FieldGuesser source="partNumberId" />
    </ReferenceField>
    <ReferenceField
      label="Part number label"
      source="partNumber"
      reference="admin/part_numbers"
      sortBy="partNumber.libelle"
    >
      <FieldGuesser source="libelle" />
    </ReferenceField>
    <ReferenceField
      label="Part number spare part"
      source="partNumber"
      reference="admin/part_numbers"
      sortBy="partNumber.sparePart"
    >
      <FieldGuesser source="sparePart" />
    </ReferenceField>
    <FieldGuesser source={ "createdAt" } />
    <FieldGuesser source={ "updatedAt" } />
  </ListGuesser>
);

export default LicenceList;
