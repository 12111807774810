const _LogsList = _interopRequireDefault(require("./logsList"));
Object.defineProperty(exports, "LogsList", {
  enumerable: true,
  get: function () {
    return _LogsList.default;
  },
});

const _LogsShow = _interopRequireDefault(require("./logsShow"));
Object.defineProperty(exports, "LogsShow", {
  enumerable: true,
  get: function () {
    return _LogsShow.default;
  },
});

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : { default: obj };
}
