const _PrivacyPolicyCreate = _interopRequireDefault(require("./privacyPolicyCreate"));
Object.defineProperty(exports, "PrivacyPolicyCreate", {
    enumerable: true,
    get: function () {
        return _PrivacyPolicyCreate.default;
    }
});

const _PrivacyPolicyList = _interopRequireDefault(require("./privacyPolicyList"));
Object.defineProperty(exports, "PrivacyPolicyList", {
    enumerable: true,
    get: function () {
        return _PrivacyPolicyList.default;
    }
});

const _PrivacyPolicyShow = _interopRequireDefault(require("./privacyPolicyShow"));
Object.defineProperty(exports, "PrivacyPolicyShow", {
    enumerable: true,
    get: function () {
        return _PrivacyPolicyShow.default;
    }
});

const _PrivacyPolicyEdit = _interopRequireDefault(require("./privacyPolicyEdit"));
Object.defineProperty(exports, "PrivacyPolicyEdit", {
    enumerable: true,
    get: function () {
        return _PrivacyPolicyEdit.default;
    }
});

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
