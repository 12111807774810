import { EditGuesser, InputGuesser } from "@api-platform/admin";
import React from "react";
import { DateField, maxLength, minLength } from "react-admin";
import InputJson from "../util_components/inputJson";
import TorqueUnitInput from "../util_components/torqueUnitInput";
import { UserEditToolbar } from "./userEditToolbar";

const UserEdit = (props) => {
  let params = new URLSearchParams(props.location.search);
  return (
    <EditGuesser
      basePath={props.basePath + "?isAdmin=" + params.get("isAdmin")}
      redirect="show"
      actions={<UserEditToolbar />}
      {...props}
    >
      <InputJson source={"configuration"} />
      <InputGuesser source={"firstName"} />
      <InputGuesser source={"lastName"} />
      <InputGuesser source={"company"} />
      <InputGuesser source={"job"} />
      <InputGuesser source={"language"} />
      <InputGuesser
        source={"country"}
        validate={[minLength(2), maxLength(2)]}
      />
      <InputGuesser source={"email"} />
      <InputGuesser source={"plainPassword"} />
      {params.get("isAdmin") === "true" && (
        <InputGuesser source={"admin"} defaultValue={true} />
      )}
      <TorqueUnitInput source={"units"} />
      <DateField source="createdAt" showTime />
      <DateField source="updatedAt" showTime />
    </EditGuesser>
  );
};

export default UserEdit;
