import React from 'react';
import {CardContent, Typography, Card, Avatar} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {ReferenceField} from "react-admin";
import ToolSetupIcon from "../util_components/toolSetupTypeIcon";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        card: {
            minWidth: '200px',
            backgroundColor: '#FAFAFA',
        },
        inline: {
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        setup_card: {
            marginTop: 5,
            width: 400,
            backgroundColor: '#FFFFFF',
            display: 'flex',
            justifyContent: 'space-around',
            alignItems: 'center',
            borderRadius: 6
        },
        setup_text: {
            width: 250,
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column'
        },
        text: {
            width: 250,
            textAlign: 'center'
        },
        setup_number: {
            color: theme.palette.getContrastText('#34786C'),
            backgroundColor: '#34786C',
        },
    }),
);

const ToolSetupItem = ({record, order}) => {
    const classes = useStyles();
    const setup = record['setup'];
    const type = record['type'];

    return (
        <div className={classes.setup_card}>
            {order  && <Avatar className={classes.setup_number}>{order}</Avatar>}
            <ToolSetupIcon type={type}/>
            <div className={classes.setup_text}>
                <Typography className={classes.text} variant="body1" gutterBottom noWrap>{record['name']}</Typography>
                <Typography variant="subtitle2" gutterBottom>
                    { setup.sequence && `${setup['sequence']} x `}
                    { setup.power && `${setup['power']}% `}
                    { setup.torque && `${setup['torque']['value']} ${setup['torque']['unit']} `}
                    { setup.angle && `${setup['angle']}° `}
                    { setup.time && `${setup['time']} secs `}
                </Typography>
            </div>
        </div>
    )
}

const ToolSetupsCard = (props) => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardContent>
                <Typography variant="h6" gutterBottom>{props.label ? props.label : ''}</Typography>
                <ReferenceField {...props} order='1' label="Setup_1" source="setup1" reference="tool_setups">
                    <ToolSetupItem {...props} />
                </ReferenceField>
                <ReferenceField {...props} order='2' label="Setup_2" source="setup2" reference="tool_setups">
                    <ToolSetupItem {...props} />
                </ReferenceField>
                <ReferenceField {...props} order='3' label="Setup_3" source="setup3" reference="tool_setups">
                    <ToolSetupItem {...props} />
                </ReferenceField>
                <ReferenceField {...props} order='4' label="Setup_4" source="setup4" reference="tool_setups">
                  <ToolSetupItem {...props} />
                </ReferenceField>
                <ReferenceField {...props} order='5' label="Setup_5" source="setup5" reference="tool_setups">
                  <ToolSetupItem {...props} />
                </ReferenceField>
                <ReferenceField {...props} order='6' label="Setup_6" source="setup6" reference="tool_setups">
                  <ToolSetupItem {...props} />
                </ReferenceField>
            </CardContent>
        </Card>
    );
};

export default ToolSetupsCard;


