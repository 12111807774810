import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import Tooltip from "@material-ui/core/Tooltip";
import CardContent from '@material-ui/core/CardContent';
import BluetoothConnectedIcon from '@material-ui/icons/BluetoothConnected';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import BatteryField from "../util_components/batteryField";
import Typography from '@material-ui/core/Typography';
import StateChip from "../util_components/stateChip";
import Chip from "@material-ui/core/Chip";
import {dateTimeTzToLocale} from "../functions/util/utilDate";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        card: {
            minWidth: '200px',
        },
        inlineChips: {
            display: 'flex',
            justifyContent: 'space-evenly',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        },
        inline: {
            display: 'flex',
            justifyContent: 'space-between',
            paddingLeft: theme.spacing(1),
            paddingBottom: theme.spacing(1),
        }
    }),
);

const ToolInfoCard = ({record}) => {
    const classes = useStyles();
    return (
        <Card className={classes.card}>
            <CardContent>
                <div className={classes.inlineChips}>
                    <StateChip record={record}/>
                {
                    record.lock.active ?
                            <Tooltip title={`Since ${record.lock.time}`}>
                                <Chip label="Locked"
                                      style={{ backgroundColor:'#26c6da', color:'white'}}
                                      icon={
                                    <LockOutlinedIcon style={{ color: 'white', fontSize: 16 }}/>
                                }/>
                            </Tooltip>
                        : null
                }
                </div>
                <div className={classes.inline}>
                    <Tooltip title={`Last sync with the tool`}>
                        <BluetoothConnectedIcon/>
                    </Tooltip>
                    <Typography>
                        {dateTimeTzToLocale(record.lastSync)}
                    </Typography>
                </div>
                <div className={classes.inline}>
                    <BatteryField record={record}/>
                    <Typography>
                        {record.battery}%
                    </Typography>
                </div>
            </CardContent>
        </Card>
    );
};

export default ToolInfoCard;


