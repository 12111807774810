import React from "react";
import {
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import CustomPagination from "../util_components/customPagination";

const exporter = async (records, _) => {
    const data = records.map((record) => {
        delete record[ "@id" ];
        delete record[ "@type" ];
        delete record[ "id" ];
        return {
            ...record,
        };
    });

    jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
        downloadCSV(csv, "contacts");
    });
};

const ContactList = props => (
    <ListGuesser
        exporter={ exporter }
        perPage={ 50 }
        pagination={ <CustomPagination { ...props } /> }
        { ...props }
    >
        <FieldGuesser source={ "codeCountry" } />
        <FieldGuesser source={ "phoneNumber" } />
        <FieldGuesser source={ "email" } />
    </ListGuesser>
);

export default ContactList;
