import InputGuesser from "@api-platform/admin/lib/InputGuesser";
import Introspecter from "@api-platform/admin/lib/Introspecter";
import { Filter } from "ra-ui-materialui";
import React, { useEffect, useState } from "react";

const ExtendFilterIntrospecter = ({
  fields,
  readableFields,
  writableFields,
  schema,
  schemaAnalyzer,
  hasShow,
  hasEdit,
  ...rest
}) => {
  const [filtersParameters, setFiltersParameters] = useState([]);

  useEffect(() => {
    if (schema) {
      schemaAnalyzer
        .getFiltersParametersFromSchema(schema)
        .then((parameters) => setFiltersParameters(parameters));
    }
  }, [schema, schemaAnalyzer]);

  const filtersToAddKeys = rest.filterstoadd.map(
    (filterToAdd) => filterToAdd.name
  );

  return (
    <Filter {...rest}>
      {filtersParameters
        .filter(
          (filterParameter) =>
            !filtersToAddKeys.find(
              (filterKey) => filterKey === filterParameter.name
            )
        )
        .map((filterParameter) => (
          <InputGuesser
            key={filterParameter.name}
            source={filterParameter.name}
            alwaysOn={filterParameter.isRequired}
          ></InputGuesser>
        ))}
      {rest.filterstoadd.map((filterToAdd) => filterToAdd.component)}
    </Filter>
  );
};
export const FilterGuesserExtend = (props) => {
  return <Introspecter component={ExtendFilterIntrospecter} {...props} />;
};
