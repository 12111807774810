import React from "react";
import { EditButton, ListButton, RefreshButton, TopToolbar } from "react-admin";
import UserActivationButton from "./../user_views/userActivationButton";
import UserDeleteButton from "./userDeleteButton";

export class UserShowToolbar extends React.Component {
  render() {
    const isAdmin =
      this.props.data &&
      this.props.data.roles &&
      !!this.props.data.roles.find((role) => role === "ROLE_ADMIN");

    return (
      <TopToolbar>
        <ListButton
          basePath={this.props.basePath + "?isAdmin=" + isAdmin}
          record={this.props.data}
        />
        <RefreshButton
          basePath={this.props.basePath}
          record={this.props.data}
        />
        <EditButton basePath={this.props.basePath} record={this.props.data} />
        {this.userIsActive(this.props.data) && (
          <UserActivationButton record={this.props.data} />
        )}
        <UserDeleteButton record={this.props.data} />
      </TopToolbar>
    );
  }

  userIsActive(data) {
    return data !== undefined && !data.isActive;
  }
}
