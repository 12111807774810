import { FieldGuesser } from "@api-platform/admin";
import BuildIcon from "@material-ui/icons/Build";
import EventNoteTwoToneIcon from "@material-ui/icons/EventNoteTwoTone";
import React from "react";
import {
  Datagrid,
  DateField,
  EditButton,
  Pagination,
  ReferenceManyField,
  Show,
  Tab,
  TabbedShowLayout,
  TextField,
} from "react-admin";
import EventShow from "../event_views/eventShow";
import StateChip from "../util_components/stateChip";
import ToolSetupCard from "../util_components/toolShowHeader";

const EventDetailCard = (props) => {
  return <EventShow {...props} />;
};

const ToolShow = (props) => {
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="tool infos" icon={<BuildIcon />}>
          <ToolSetupCard {...props} />
          <FieldGuesser source={"model"} addLabel={true} />
          <FieldGuesser source={"name"} addLabel={true} />
          <FieldGuesser source={"bluetoothId"} addLabel={true} />
          <FieldGuesser source={"serial"} addLabel={true} />
          <FieldGuesser source={"attributes"} addLabel={true} />
          <FieldGuesser source={"version"} addLabel={true} />
          <FieldGuesser source={"totalAttributes"} addLabel={true} />
          <FieldGuesser source={"mode"} addLabel={true} />
          <FieldGuesser source={"lock"} addLabel={true} />
          <FieldGuesser source={"lastSync"} addLabel={true} />
          <FieldGuesser source={"battery"} addLabel={true} />
          <FieldGuesser source={"binaryToolOffset"} addLabel={true} />
          <FieldGuesser source={"resultToolOffset"} addLabel={true} />
          <FieldGuesser source={"archived"} addLabel={true} />
          <FieldGuesser source={"uuid"} addLabel={true} />
          <DateField source={"createdAt"} />
          <DateField source={"updatedAt"} />
          <FieldGuesser source={"state"} addLabel={true} />
        </Tab>
        <Tab label="events" icon={<EventNoteTwoToneIcon />} path="events">
          <ReferenceManyField
            reference="events"
            target="tool"
            addLabel={false}
            pagination={<Pagination />}
          >
            <Datagrid expand={<EventDetailCard />}>
              <TextField source="name" />
              <FieldGuesser source={"type"} />
              <StateChip source={"state"} />
              <DateField source="date" />
              <EditButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default ToolShow;
