import React, { useEffect, useState } from 'react'
import ToolInfoCard from '../tool_views/toolInfoCard'
import ToolAttributesCard from '../tool_views/toolAttributesCard'
import ToolSetupsCard from '../tool_views/toolSetupsCard'
import { createStyles, makeStyles } from '@material-ui/core/styles'
import { Title } from 'react-admin'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Config from '../Config'

const getHeaders = () =>
  localStorage.getItem('token')
    ? {
      authorization: `Bearer ${localStorage.getItem('token')}`,
    }
    : {}

const useStyles = makeStyles((theme) =>
  createStyles({
    column: {
      display: 'flex',
      justifyContent: 'space-evenly',
      paddingLeft: theme.spacing(1),
      paddingBottom: theme.spacing(1),
    },
    accordion: {
      marginBottom: theme.spacing(3),
    },
  }),
)

const fetchSparePartChanges = (toolUuid) => {
  const eventsEndpoint = `${Config.REACT_APP_API_ENTRYPOINT}/events?name=SPARE_PART_CHANGE&tool=${toolUuid}`

  return fetch(eventsEndpoint, {
    method: 'GET',
    headers: getHeaders(),
    priority: 'high',
  }).
    then((response) => {
      if (response.ok) {
        return response.json()
      }
      throw response
    }).
    then((data) => data).
    catch((error) => error)
}

const ToolShowHeader = (props) => {
  const classes = useStyles()

  const [events, setEvents] = useState([])

  useEffect(() => {
    console.log('effect')
    const fetchData = async () => {
      const eventList = await fetchSparePartChanges(props.record.uuid)
      setEvents(eventList)
      console.log('events', eventList)
    }
    fetchData()
  }, [])

  return (
    <>
      <div className={classes.column}>
        <ToolInfoCard record={props.record} />
        <ToolAttributesCard label={'Attributes'} record={props.record
          ? props.record.attributes
          : undefined} />
        <ToolAttributesCard label={'Total attributes'} record={props.record
          ? props.record.totalAttributes
          : undefined} />
        <ToolSetupsCard label={'Setups'} {...props} />
      </div>

      <div>
        {events && events.map((event) => {
          const date = new Date(event.date)
          return (
            <Accordion className={classes.accordion} key={event.uuid}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
              >
                <Typography>Archived on {date.toLocaleDateString()} from
                  PCB {event.archivedBluetoothId}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.column}>
                  <ToolAttributesCard label={'Attributes'}
                                      record={event.archivedAttributes} />
                  <ToolAttributesCard label={'Total attributes'}
                                      record={event.archivedTotalAttributes} />
                </div>
              </AccordionDetails>
            </Accordion>
          )
        })}
      </div>
    </>
  )
}

export default ToolShowHeader
