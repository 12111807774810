import React from 'react';
import { CheckboxGroupInput } from 'react-admin';

const ApiKeyPermissionInput = ({source}) => {
    const permissions = [
        { _id: "LICENCE_CREATE", label: 'Create level 0 licence' },
        { _id: "LICENCE_UPDATE", label: 'Upgrade existing licence' },
    ];
    return <CheckboxGroupInput source={source} choices={permissions} optionText="label" optionValue="_id" />
}

export default ApiKeyPermissionInput;
