import { CreateGuesser, InputGuesser } from "@api-platform/admin";
import React from "react";
import { maxLength, minLength } from "react-admin";
import TorqueUnitInput from "../util_components/torqueUnitInput";
const UserCreate = (props) => {
  let params = new URLSearchParams(props.location.search);
  return (
    <CreateGuesser
      basePath={props.basePath + "?isAdmin=" + params.get("isAdmin")}
      redirect="show"
      {...props}
    >
      <InputGuesser source={"firstName"} />
      <InputGuesser source={"lastName"} />
      <InputGuesser source={"company"} />
      <InputGuesser source={"job"} />
      <InputGuesser source={"language"} />
      <InputGuesser
        source={"country"}
        validate={[minLength(2), maxLength(2)]}
      />
      <InputGuesser source={"email"} />
      <InputGuesser source={"plainPassword"} />
      {params.get("isAdmin") === "true" && (
        <InputGuesser source={"admin"} defaultValue={true} />
      )}
      <TorqueUnitInput source={"units"} />
    </CreateGuesser>
  );
};

export default UserCreate;
