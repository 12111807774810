import { FieldGuesser, ListGuesser } from "@api-platform/admin";
import React from "react";
import jsonExport from "jsonexport/dist";
import { downloadCSV } from "react-admin";
import { TextField } from "react-admin";
import CustomPagination from "../util_components/customPagination";

const exporter = async (records, _) => {
  const data = records.map((record) => {
    delete record[ "@id" ];
    delete record[ "@type" ];
    delete record[ "id" ];
    delete record[ "plainKey" ];
    return {
      ...record,
      createdAt: record.createdAt ? new Date(record.createdAt).toLocaleString() : "",
      updatedAt: record.updatedAt ? new Date(record.updatedAt).toLocaleString() : "",
    };
  });

  jsonExport(data, { rowDelimiter: ";" }, (err, csv) => {
    downloadCSV(csv, "licenceGenerationKeys");
  });
};

const LicenceGenerationKeyList = (props) => (
  <ListGuesser
    exporter={ exporter }
    perPage={ 50 }
    pagination={ <CustomPagination { ...props } /> }
    { ...props }
  >
    <TextField source={ "name" } />
    <TextField source={ "prefix" } />
    <TextField source={ "permissions" } sortable={ false } />
    <FieldGuesser source={ "createdAt" } />
    <FieldGuesser source={ "updatedAt" } />
  </ListGuesser>
);

export default LicenceGenerationKeyList;
